<template>
    <div class="h-full">
        <div class="sl-border">
            <el-table :data="pageDataSource.Result" border @current-change="handleCurrentChange" highlight-current-row>
                <el-table-column type="index" width="50" label="序号" align="center"
                    header-align="center"></el-table-column>
                <el-table-column v-for="(col, index)  in pageDataSource.ColDefs.BodyFieldParams" :key="index"
                    :prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam, col)"
                    :min-width="parseInt(col.Width)" v-if="col.Visible">
                    <template slot-scope="scope">
                        <span> {{ scope.row[col.FieldName] }}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page">
                <el-pagination @size-change="pageSizeChange" @current-change="currentChange"
                    :current-page="queryParam.PageIndex" :page-sizes="[10, 30, 50, 100]"
                    :page-size="queryParam.PageSize" layout="total, sizes, prev, pager, next, jumper"
                    :total="pageDataSource.TotalCount"></el-pagination>
                <el-button type="text" class="btn-refurbish" @click="refreshPage">刷新</el-button>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            pageDataSource: {
                ColDefs: {},
                Result: [],
                TotalCount: 0
            },
            queryParam: {
                PageIndex: 1,
                PageSize: 10,
                Params: {
                }
            },
        }
    },
    props: {
        pageUrl: {
            type: String,
            default: ''
        },
        orderId: {
            type: String,
            default: ''
        },
        orderNo: {
            type: String,
            default: ''
        },
    },
    watch: {
        orderId: function (newVal, oldVal) {
            if (newVal && newVal != oldVal) {
                this.initPage();
            }
        },
    },
    mounted() {
        this.initPage();
    },
    methods: {
        refreshPage() {
            this.initPage();
        },
        pageSizeChange(val) {
            this.queryParam.PageSize = val;
        },
        currentChange(val) {
            this.queryParam.PageIndex = val;
            this.initPage();
        },
        handleCurrentChange: function (val) {
            this.$emit("onSelectedRow", val);
        },
        initPage() {
            var _this = this;
            if (_this.orderId) {
                _this.queryParam.Params.StandardOrderId = _this.orderId;
            } else if (_this.orderNo) {
                _this.queryParam.Params.OrderNo = _this.orderNo;
            } else {
                return;
            }
            _this.$ajax.query(_this.pageUrl, "post", _this.queryParam, (result) => {
                _this.pageDataSource = result;
            });
        },
    },
}
</script>
